import React, { Component } from "react"
import styled from "styled-components"
import { Row, Col, Container } from "styled-bootstrap-grid"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import { withTranslation } from "react-i18next"

const WhiteBackground = styled.div`
    background-color: ${colors.white};
    padding-top: 90px;
    padding-bottom: 100px;
    @media(min-width: ${breakpoints.md}){
        padding-top: 160px;
        padding-bottom: 160px;
    }
`

const RowStyled = styled(Row)`
    margin: 0 33px 0 32px;
    @media(min-width: ${breakpoints.lg}){
        margin: 0;
    }
`

const Title = styled.div`
    color: ${colors.darkBlue};
    ${fonts.jaggerRegular};
    font-size: 28px;
    letter-spacing: -0.8px;
    line-height: 42px;
    max-width: 240px;
`

const Description = styled.div`
    font-size: 15px;
    color: ${colors.darkBlue};
    ${fonts.dmSansRegular};
    letter-spacing: 0;
    line-height: 23px;
    max-width: 360px;
    padding-top: 27px;
`

const CertificateIcon = styled.img`
    height: 82px;
    width: 82px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    @media(min-width: ${breakpoints.md}){
        height: 100px;
        width: 100px;
    }
    @media(min-width: ${breakpoints.xl}){
        height: 120px;
        width: 120px;
    }
`


class CertificationsBlock extends Component {
  state = {
    isMobile: false
  }


  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }
  };

  componentWillUnmount = () => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
  };

  updateWindowDimensions = () => {
    if (typeof window !== 'undefined') {
      this.setState({isMobile: window.innerWidth < 992});
    }
  };
  render() {
    const { data } = this.props
    const { fields, primary } = data;
    const certifications = []

    fields.forEach(({image}) => {
      certifications.push({certificateIcon: image.url})
    })

    return (
      <WhiteBackground>
        <Container>
          <RowStyled>
            <Col md={6}>
              <Row>
                {certifications.map((certificate, index) => {
                  if (this.state.isMobile === true && index > 5) {
                    return <div key={index} />
                  }
                  return (
                    <Col lg={3} sm={4} xs={4} key={index}>
                      <CertificateIcon src={certificate.certificateIcon} />
                    </Col>
                  )
                })}
              </Row>
            </Col>
            <Col md={4} mdOffset={2}>
              <Title>
                {primary.title}
              </Title>
              <Description>
                {primary.content}
              </Description>
            </Col>
          </RowStyled>
        </Container>
      </WhiteBackground>
    )
  }
}

export default withTranslation()(CertificationsBlock);
