import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "styled-bootstrap-grid";
import Img from "gatsby-image";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import { withTranslation } from "react-i18next"
import GetStartedButton from "../core/GetStartedButton"

const RowStyled = styled(Row)`
    margin: 0 33px 0 32px;
    margin-bottom: ${({isLast}) => isLast ? 0 : 81}px;
    @media(min-width: ${breakpoints.lg}){
        margin-bottom: ${({isLast}) => isLast ? 0 : 178}px;
    }
`

const BlueBackground = styled.div`
    background-color: ${colors.lightBlue};
    padding-top: 90.5px;
    padding-bottom: 100px;
    @media(min-width: ${breakpoints.md}){
        padding-top: 131px;
        padding-bottom: 141px;
    }
`

const Title = styled.div`
    ${fonts.jaggerRegular};
    color: ${colors.darkBlue};
    font-size: 28px;
    letter-spacing: -0.8px;
    line-height: 42px;
    padding-bottom: 27px;
    padding-top: 0;
`

const Text = styled.div`
    ${fonts.dmSansRegular};
    color: ${colors.darkBlue};
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    padding-bottom: 40px;
    text-align: left;
    @media(min-width: ${breakpoints.lg}){
        max-width: 500px;
    }
`

const QuotationBox = styled.div`
      @media(min-width: ${breakpoints.lg}){
          text-align: left;
          margin-bottom: auto;
          padding-top: 40px;
          margin-right: ${props => props.right ? "0" : "auto"};
          margin-left: ${props => props.right ? "auto" : "0"};
      }
      @media(min-width: ${breakpoints.xl}){
          padding-top: 92px;
          max-width: 360px;
      }
`

const PreviewImage = styled(Img)`
    max-width: 345.6px;
    margin: 90px auto auto auto;
    -webkit-box-shadow: -11px 13px 32px 0 rgba(0,0,0,0.28);
    -moz-box-shadow: -11px 13px 32px 0 rgba(0,0,0,0.28);
    box-shadow: -11px 13px 32px 0 rgba(0,0,0,0.28);
    @media(min-width: ${breakpoints.md}){
        max-width: 450px;
    }
    @media(min-width: ${breakpoints.lg}){
        max-width: 500px;
        margin: 0 auto auto auto;
    }
    @media(min-width: ${breakpoints.xl}){
        max-width: 720px;
    }
`

// const TrackingPreview = styled(Img)`
//     max-width: 394.4px;
//     margin: 90px auto auto auto;
//     @media(min-width: ${breakpoints.md}){
//         max-width: 450px;
//     }
//     @media(min-width: ${breakpoints.lg}){
//         max-width: 500px;
//         margin: auto auto auto 0;
//     }
//     @media(min-width: ${breakpoints.xl}){
//         max-width: 647px;
//     }
// `

class PreviewBlock extends Component {

  render() {
    const { data } = this.props

    return (
      <BlueBackground>
        <Container>
            {data.fields.map((feature, index) => {
              const left = feature.image_position === true;
              const length = data.fields.length
              return (
                <RowStyled key={index} isLast={index + 1 === length}>
                  <Col lg={5} lgOrder={left ? "first" : "last"} >
                    <QuotationBox right={!left}>
                      <Title>{feature.title}</Title>
                      <Text>{feature.content[0].text}</Text>
                      <GetStartedButton />
                    </QuotationBox>
                  </Col>
                  <Col lg={7}>
                    {(feature.imageSharp && feature.imageSharp.childImageSharp) && <PreviewImage fluid={feature.imageSharp.childImageSharp.fluid} alt={feature.image.alt}/>}
                  </Col>
                </RowStyled>
              )
            })}
        </Container>
      </BlueBackground>
    )
  }
}

export default withTranslation()(PreviewBlock);
