import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import PropTypes from "prop-types"

const StyledLink = styled.a`
    cursor: pointer;
    background-color: ${props => props.colour === 'white' ? colors.white: colors.blue};
    ${fonts.dmSansMedium};
    font-size: 11px;
    color: ${props => props.colour === 'white' ? colors.blue: colors.white};
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    padding: 12px 18px;
    border: 1px solid ${colors.blue};
    border-radius: 19px;
    transition: .5s;
    margin-right: 7.5px;
    margin-left: 7.5px;
    max-width: 140px;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        background-color: ${props => props.colour === 'white' ? colors.blue : colors.white};
        color: ${props => props.colour === 'white' ? colors.white: colors.blue};
        transition: .5s;
        :after {
            content: '';
            -webkit-tap-highlight-color: transparent;
        }
    }
    @media(min-width: ${breakpoints.md}){
        padding: 12px 25px;
    }
`

const ButtonExternal = (props) => {
  const {href, children, colour} = props
  return (
    <StyledLink href={href} colour={colour} target="_blank" rel="noopener noreferrer">{children}</StyledLink>
  )
}

ButtonExternal.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  colour: PropTypes.string,
};

export default ButtonExternal;