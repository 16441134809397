import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "styled-bootstrap-grid";
import Img from "gatsby-image";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import GetStartedButton from "../core/GetStartedButton"


const RowStyled = styled(Row)`
    margin: 0 33px 0 32px;
    @media(min-width: ${breakpoints.lg}){
        margin: 0;
    }
`

const WhiteBackground = styled.div`
    background-color: ${colors.white};
    padding-top: 90.3px;
    padding-bottom: 90px;
    @media(min-width: ${breakpoints.md}){
        padding-top: 131px;
        padding-bottom: 141px;
    }
`

const IntroImage1 = styled(Img)`
    max-width: 184.3px;
    -webkit-box-shadow: -11px 13px 32px 0 rgba(0,0,0,0.28);
    -moz-box-shadow: -11px 13px 32px 0 rgba(0,0,0,0.28);
    box-shadow: -11px 13px 32px 0 rgba(0,0,0,0.28);
    right: -6%;
    @media(min-width: ${breakpoints.sm}){
        right: -16%;
    }
    @media(min-width: ${breakpoints.md}){
        max-width: 288px;
        right: -21%;
    }
    @media(min-width: ${breakpoints.lg}){
        max-width: 288px;
        right: -21%;
    }
`
const IntroImage2 = styled(Img)`
    max-width: 184.3px;
    -webkit-box-shadow: -11px 13px 32px 0px rgba(0,0,0,0.28);
    -moz-box-shadow: -11px 13px 32px 0px rgba(0,0,0,0.28);
    box-shadow: -11px 13px 32px 0px rgba(0,0,0,0.28);
    bottom: 74%;
    left: 16%;
    margin-bottom: -259px;
    margin-left: auto;
    margin-right: auto;
    @media(min-width: ${breakpoints.sm}){
        bottom: 74%;
        left: 10%;
    }
    @media(min-width: ${breakpoints.md}){
        max-width: 288px;
        bottom: 73%;
        left: 20%;
        margin: auto auto -397px auto;
    }
    @media(min-width: ${breakpoints.lg}){
        max-width: 288px;
        bottom: 73%;
        left: 20%;
        margin: auto auto -397px auto;
    }
    @media(min-width: ${breakpoints.xl}){
        right: 0;
    }
`

const Title = styled.div`
    ${fonts.jaggerRegular};
    color: ${colors.darkBlue};
    font-size: 28px;
    letter-spacing: -0.8px;
    line-height: 42px;
    padding-bottom: 27px;
`

const Text = styled.div`
    ${fonts.dmSansRegular};
    color: ${colors.darkBlue};
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    padding-bottom: 40px;
`

const QuotationBox = styled.div`
      text-align: left;
      padding-top: 81px;
      @media(min-width: ${breakpoints.lg}){
          text-align: left;
          margin-bottom: auto;
          max-width: 360px;
          padding-left: 100px;
      }
      @media(min-width: ${breakpoints.xl}){
          padding-top: 139px;
          padding-left: 216px;
      }
`


class IntroBlock extends Component {
  render() {
    const { primary: data } = this.props.data;

    return (
      <WhiteBackground>
        <Container>
          <RowStyled>
            <Col lg={6}>
              <IntroImage1 fluid={data.intro_image_1Sharp.childImageSharp.fluid} alt={data.intro_image_1.alt}/>
              <IntroImage2 fluid={data.intro_image_2Sharp.childImageSharp.fluid} alt={data.intro_image_2.alt}/>
            </Col>
            <Col lg={6}>
              <QuotationBox>
                <Title>{data.intro_title}</Title>
                <Text>{data.intro_text}</Text>
                <GetStartedButton />
              </QuotationBox>
            </Col>
          </RowStyled>
        </Container>
      </WhiteBackground>
    )
  }
}

export default IntroBlock;
