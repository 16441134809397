import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "styled-bootstrap-grid";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import HexagonImg from '../../resources/img/why-mfo/hexagon.svg';
import BlueLineImg from '../../resources/img/why-mfo/blue-line.svg'
import { withTranslation } from "react-i18next"

const RowStyled = styled(Row)`
    margin: 0 33px 0 32px;
    @media(min-width: ${breakpoints.lg}){
        margin: 0;
    }
`

const WhiteBackground = styled.div`
    background-color: ${colors.white};
    padding-top: 91px;
    padding-bottom: 50px;
    @media(min-width: ${breakpoints.md}){
        padding-top: 160px;
        padding-bottom: 160px;
    }
`

const Title = styled.div`
    font-size: 28px;
    color: ${colors.darkBlue};
    line-height: 42px;
    letter-spacing: -0.8px;
    text-align: center;
    padding-bottom: 10px;
    @media(min-width: ${breakpoints.lg}){
        padding-bottom: 88px;
    }
`

const Functionality = styled.div`
    max-width: 216px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
`

const Hexagon = styled.div`
    background: url(${HexagonImg}) no-repeat;
    background-size: 150px 150px;
    margin-top: 50px;
    @media(min-width: ${breakpoints.md}){
        margin-top: 0;
    }
`

const IconBox = styled.div`
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Icon = styled.img`
    height: 58px;
    width: 66px;
`

const Description = styled.div`
    ${fonts.jaggerRegular};
    color: ${colors.darkBlue};
    font-size: 22px;
    letter-spacing: -0.6px;
    text-align: center;
    line-height: 32px;
    background: transparent;
    margin-top: 22px;
    margin-bottom: 50px;
    max-width: 200px;
    @media(min-width: ${breakpoints.md}){
        margin-bottom: 0;
    }
`

const BlueLine = styled.img`
    width: 40px;
    height: 3px;
    transform: rotate(90deg);
    @media(min-width: ${breakpoints.md}){
        width: 25px;
        height: 1px;
        position: absolute;
        top: 31%;
        left: 92%;
        transform: none;
    }
    @media(min-width: ${breakpoints.lg}){
        width: 80px;
        left: 83%;
        top: 32%;
        height: 2px;
    }
    @media(min-width: ${breakpoints.xl}){
        width: 100px;
        left: 85%;
        height: 3px;
    }
`

class FunctionalityBlock extends Component {

  render() {
    const { data: { primary : data} } = this.props;

    const hexagons = [
      {
        icon: data.first_functionality_icon.url,
        description: data.first_functionality_title,
      },
      {
        icon: data.second_functionality_icon.url,
        description: data.second_functionality_title,
      },
      {
        icon: data.third_functionality_icon.url,
        description: data.third_functionality_title,
      },
      {
        icon: data.fourth_functionality_icon.url,
        description: data.fourth_functionality_title,
      },

    ]
    return (
      <WhiteBackground>
        <Container>
          <RowStyled>
            <Col sm={12}>
              <Title>{data.title}</Title>
            </Col>
            {hexagons.map((hexagon, index) => {
              return (
                <Col md={3} key={index}>
                  <Functionality>
                    <Hexagon>
                      <IconBox>
                        <Icon src={hexagon.icon} />
                      </IconBox>
                    </Hexagon>
                    <Description>{hexagon.description}</Description>
                    {index !== hexagons.length - 1 ?
                      <BlueLine src={BlueLineImg}/>
                      : <div/>
                    }
                  </Functionality>
                </Col>
              )
            })}
          </RowStyled>
        </Container>
      </WhiteBackground>
    )
  }
}

export default withTranslation()(FunctionalityBlock);
