import React, { Component } from "react"
import styled from "styled-components"
import { Row, Col, Container } from "styled-bootstrap-grid"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import Button from "../core/Button"
import ButtonExternal from "../core/ButtonExternal"
import { withTranslation } from "react-i18next"

const RowStyled = styled(Row)`
    margin: 0 33px 0 32px;
    @media(min-width: ${breakpoints.lg}){
        margin: 0;
    }
`

const BlueBackground = styled.div`
    background-color: ${colors.lightBlue};
    padding-top: 110px;
    padding-bottom: 100px;
    @media(min-width: ${breakpoints.md}){
        padding-top: 130px;
        padding-bottom: 130px;
    }
`

const Logo = styled.img`
    margin: auto;
    width: auto;
    height: 90px;
    display: block;
    object-fit: contain;
`

const ReviewContainer = styled.div`
    margin: auto;
    text-align: center;
`

const Review = styled.div`
    color: ${colors.darkBlue};
    ${fonts.jaggerRegular};
    display: block;
    font-size: 24px;
    letter-spacing: -0.77px;
    margin: auto;
    line-height: 32px;
    padding-top: 40px;
    padding-bottom: 50px;
    @media(min-width: ${breakpoints.md}){
        font-size: 28px;
        line-height: 42px;
        letter-spacing: -0.9px;
        max-width: 864px;
    }
`

class ReviewsBlock extends Component {

  render() {
    const {data: { primary : data}, t} = this.props;

    return (
      <BlueBackground>
        <Container>
          <RowStyled>
            <Col sm={12}>
              <Logo src={data.image.url}/>
            </Col>
            <Col sm={12}>
              <ReviewContainer>
                <Review>{data.text}</Review>
                <ButtonExternal href={'https://www.bansard.com'} colour={colors.blue}>
                  {t('core.readMore')}
                </ButtonExternal>
              </ReviewContainer>
            </Col>
          </RowStyled>
      </Container>
  </BlueBackground>
  )
  }
}

export default withTranslation()(ReviewsBlock);

