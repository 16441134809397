import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Hero from '../components/core/Hero'
import get from 'lodash/get';
import IntroBlock from "../components/why-mfo/Intro"
import FunctionalityBlock from "../components/why-mfo/FunctionalityBlock"
import ReviewsBlock from "../components/why-mfo/ReviewsBlock"
import CertificationsBlock from "../components/why-mfo/CertificationsBlock"
import PreviewBlock from "../components/why-mfo/PreviewBlock"
import { withTranslation } from "react-i18next"
import GetStartedBlock from "../components/core/GetStartedBlock"
import {graphql} from "gatsby";

class MfoPage extends React.Component {
  render() {
    const i18n = this.props.i18n
    const path = this.props.location.pathname;
    const data = get(this, 'props.data.prismic.allWhy_mfos.edges');
    const introData = [];
    const previewData = [];
    const functionalityData = [];
    const reviewsData = [];
    const certificationsData = [];
    const mfo = data.find((ver) => {
      return ver.node._meta.lang.includes(i18n.language)
    }).node
    const { meta_title, meta_description, hero_title, hero_intro, hero_imageSharp } = mfo;

    mfo.body.forEach((data) => {
        if (data.__typename === "PRISMIC_Why_mfoBodyIntro_block") {
            introData.push(data)
        }
        if (data.__typename === "PRISMIC_Why_mfoBodyPreview_block") {
            previewData.push(data)
        }
        if (data.__typename === "PRISMIC_Why_mfoBodyFunctionality_block") {
            functionalityData.push(data)
        }
        if (data.__typename === "PRISMIC_Why_mfoBodyReviews_block") {
            reviewsData.push(data)
        }
        if (data.__typename === "PRISMIC_Why_mfoBodyCertifications_block") {
            certificationsData.push(data)
        }
    })

    return (
      <Layout meta={{
        uid: 'mfo',
        lang: i18n.language === 'en' ? 'en-us' : 'fr-fr',
        alternateLanguages:[{ uid: 'mfo', lang: i18n.language === 'en' ? 'fr-fr': 'en-us' }],
      }}>
        <SEO title={meta_title}
             description={meta_description}
             fullTitle={true}
             path={path}/>
        <Hero image={hero_imageSharp}
              title={hero_title}
              content={hero_intro}
              top/>
        <IntroBlock data={introData[0]}/>
        <PreviewBlock data={previewData[0]}/>
        <FunctionalityBlock data={functionalityData[0]}/>
        <ReviewsBlock data={reviewsData[0]}/>
        <CertificationsBlock data={certificationsData[0]}/>
        <GetStartedBlock/>
      </Layout>
    );
  }
}

export default withTranslation()(MfoPage);

export const pageQuery = graphql`
    query mfoPageQuery {
        prismic {
            allWhy_mfos {
              edges {
                node {
                  meta_title
                  meta_description
                  hero_title
                  hero_intro
                  hero_image
                  hero_imageSharp {
                      childImageSharp {
                          fluid(quality: 100, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                      }
                  }
                  _meta {
                    lang
                  }
                  body {
                    ... on PRISMIC_Why_mfoBodyIntro_block {
                      type
                      label
                      primary {
                        intro_title
                        intro_text
                        intro_image_2
                        intro_image_2Sharp {
                            childImageSharp {
                                fluid(quality: 100, maxWidth: 600) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                        intro_image_1
                        intro_image_1Sharp {
                            childImageSharp {
                                fluid(quality: 100, maxWidth: 600) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                      }
                    }
                    ... on PRISMIC_Why_mfoBodyPreview_block {
                      type
                      label
                      fields {
                        content
                        image
                        imageSharp {
                            childImageSharp {
                                fluid(quality: 100, maxWidth: 600) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                        image_position
                        title
                      }
                    }
                    ... on PRISMIC_Why_mfoBodyFunctionality_block {
                      type
                      label
                      primary {
                        first_functionality_icon
                        first_functionality_title
                        fourth_functionality_icon
                        fourth_functionality_title
                        second_functionality_icon
                        second_functionality_title
                        third_functionality_icon
                        third_functionality_title
                        title
                      }
                    }
                    ... on PRISMIC_Why_mfoBodyReviews_block {
                      type
                      label
                      primary {
                        image
                        text
                      }
                    }
                    ... on PRISMIC_Why_mfoBodyCertifications_block {
                      type
                      label
                      fields {
                        image
                      }
                      primary {
                        content
                        title
                      }
                    }
                  }
                }
              }
            }
        }
    }
`;

